<template>
  <div class="about">
    <NavBer></NavBer>
    <div class="bg1">
      <img src="../assets/images/dynamic/24.jpg" alt="" />
    </div>

    <div class="w">
      <!-- <el-row>
        <el-col :span="14"
          ><div class="grid-content bg-purple aboutus">
            <img src="../assets/images/about/about01.jpg" alt="" />
            
            </div>
            </el-col>
        <el-col :span="10"
          ><div>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>关于我们</span>
              </div>
              <div class="text item">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 白塔寺药店始创于1872年，距今已有140余年历史，参茸、中药饮片、知名中医线上坐诊为特色，北京首批“医保定点零售药店”之一。
              </div>
            </el-card>
          </div></el-col
        >
      </el-row> -->
      <el-row>
        <el-col :span="10"
          ><div>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>联系我们</span>
              </div>
              <div class="text item">
                
                
                <div>
                  <span>联系地址：</span>
                  <i>北京市西城区阜成门内大街165号</i>
                </div>
              </div>
            </el-card>
          </div></el-col
        >
        <el-col :span="14">
          <div class="grid-content bg-purple wow">
            <!-- <img src="../assets/images/xinwen2.jpeg" alt="" /> -->
            <div class="map" id="container"> 

            </div>
            </div>
        </el-col>
      </el-row>
    </div>
    <Footbar></Footbar>
  </div>
</template>

<script scoped>
import NavBer from "../components/nav";
import Footbar from "../components/footbar";
import { WOW } from "wowjs";
export default {
  name: "about",
  components: {
    NavBer,
    Footbar,
  },
  mounted() {
    this.$nextTick(() => {
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
    this.mapLoad();
  },
  methods: {
    mapLoad() {
      /* eslint-disable */
      var center = new TMap.LatLng(39.924016 ,116.363946);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      var map = new TMap.Map(document.getElementById("container"), {
        center: center, //设置地图中心点坐标
        zoom: 18.2, //设置地图缩放级别
      });
      //初始化marker
      var marker = new TMap.MultiMarker({
        id: "marker-layer", //图层id
        map: map,
        styles: {
          //点标注的相关样式
          marker: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png",
          }),
        },
        geometries: [
          {
            //点标注数据数组
            id: "demo",
            styleId: "marker",
            position: new TMap.LatLng(39.924016 ,116.363946),
            properties: {
              title: "marker",
            },
          },
        ],
      });
      //初始化label
      var label = new TMap.MultiLabel({
        id: "label-layer",
        map: map,
        styles: {
          label: new TMap.LabelStyle({
            color: "#3777FF", //颜色属性
            size: 16, //文字大小属性
            offset: { x: 0, y: -40 }, //文字偏移属性单位为像素
            angle: 0, //文字旋转属性
            alignment: "center", //文字水平对齐属性
            verticalAlignment: "middle", //文字垂直对齐属性
          }),
        },
        geometries: [
          {
            id: "label", //点图形数据的标志信息
            styleId: "label", //样式id
            position: center, //标注点位置
            // content: "1号楼1层", //标注文本
            properties: {
              //标注点的属性数据
              title: "label",
            },
          },
        ],
      });
      /* eslint-enable */
      return;
      // var that = this;
      // maps.init("CKMBZ-COQH4-G3YUS-XERFX-AGNLF-BWBUT", () => {
      //   var myLatlng = new maps.LatLng(34.78562, 113.69234);
      //   var myOptions = {
      //     zoom: 15,
      //     center: myLatlng,
      //   };

      //   var label = new maps.Label({
      //     position: myLatlng,
      //     content: "文本标注",
      //   });
      //   that.map = new maps.Map(
      //     document.getElementById("container"),
      //     myOptions,
      //     label
      //   );
      // });
    },
  },
};
</script>

<style scoped>
.about {
  padding-top: 90px;
}
.bg1 {
  width: 100%;
  height: 400px;
  margin-bottom: 60px;
}
.bg1 img {
  width: 100%;
  height: 100%;
}

.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 500px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.aboutus {
  margin-bottom: 60px;
}
.aboutus img {
  width: 100%;
  height: 500px;
}
.clearfix span {
  font-weight: 700;
  font-size: 20px;
  color: #666;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
.text span {
  font-weight: 700;
}
.text div {
  height: 30px;
}
.text i {
  font-style: inherit;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 280px;
  margin: 150px auto 0;
}
.map {
  width: 100%;
  height:500px;
}

</style>
